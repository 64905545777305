<template>
  <div class="loginPage_wrapper Rgis_background">
    <el-card class="loginPage_box ">
      <div class="project_logo">
        <div class="loginPage_logo logo"></div>
      </div>
      <slot name="loginForm"></slot>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'login-rgis'
}
</script>

<style lang="scss" scoped>
.Rgis_background {
  background: url(./resources/background.jpg) no-repeat;
  background-size: cover;
}
</style>
